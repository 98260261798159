import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby";

import GlobalStyles from "../assets/styles/globalStyles";
import SEO from "../components/seo"
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import Slider from "../components/slider";
import Links from "../components/links";
import Card from "../components/card";

import SVGNieruchomosc from "../assets/images/nowu-biuro-nieruchomosci-icon-1.svg";
import SVGUbezpieczenia from "../assets/images/nowu-biuro-nieruchomosci-icon-2.svg";
import SVGOdszkodowania from "../assets/images/nowu-biuro-nieruchomosci-icon-3.svg";

export const query = graphql`
  {
    file(name: {eq: "nowu-biuro-nieruchomosci-kalisz"}) {
      childImageSharp {
       fluid(maxWidth: 1700, maxHeight: 700, quality: 90) {
         ...GatsbyImageSharpFluid_tracedSVG
       }
     }
   }
  }
`;

const PageStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  @media (max-width: 992px) {
    height: 100%;
  }
`;

const IndexPage = ({ data }) => (
  <PageStyles>
    <SEO />
    <GlobalStyles />
    <Navbar />
    <Slider image={data.file.childImageSharp.fluid} />
    <Links>
      <Card title="Nieruchomości" icon={<SVGNieruchomosc />} link="https://nieruchomoscikalisz.com.pl" />
      <Card title="Ubezpieczenia" icon={<SVGUbezpieczenia />} link="http://nieruchomoscikalisz.com.pl/oferta/" />
      <Card title="Odszkodowania" icon={<SVGOdszkodowania />} link="https://odszkodowaniakalisz.com.pl" />
    </Links>
    <Footer />
  </PageStyles>
)

export default IndexPage
