import React, { useState } from "react";
import styled from "styled-components";
import SVGChevron from "../assets/images/icon-chevron.svg";
import Image from "gatsby-image";

const SliderStyles = styled.section`
    flex: 1;
    position: relative;

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        height: 480px;
    }
`;

const SliderImage = styled(Image)`
    position: absolute !important;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 1280px;
    margin: 0 auto;
    color: #fff;
    position: relative;

    @media (max-width: 1280px) {
        width: 100%;
        padding: 0 60px;
    }

    @media (max-width: 992px) {
        padding: 0 20px;
    }

    h1 {
        max-width: 900px;

        @media (max-width: 992px) {
            max-width: 100%;
        } 
    }

    p {
        max-width: 900px;

        @media (max-width: 992px) {
            max-width: 100%;
        }
    }
`;

const SliderBorder = styled.div`
    height: 5px;
    width: 150px;
    border-radius: 40px;
    background-color: #8E2315;
    margin-bottom: 32px;
`;

const SliderItem = styled.div`
    width: 1280px;
    min-width: 1280px;

    @media (max-width: 1280px) {
        width: 100%;
        min-width: 100%;
  }
`;

const SliderCarousel = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 100;
    overflow: hidden;
`;

const SliderCarouselWrapper = styled.div`
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    transition: all 0.3s ease-in-out;
    width: 100%;
    transform: translateX(calc(${(props) => props.slideNumber} * 100% * -1));
`;

const SliderControls = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: 1280px) {
        right: 60px;
        top: 50%;
        bottom: initial;
        transform: translateY(-50%);
        flex-direction: column-reverse;
    }


    @media (max-width: 992px) {
        right: initial;
        left: 20px;
        top: initial;
        bottom: 0;
        transform: none;
        flex-direction: row;
    }
`;

const SliderNav = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    background-color: #fff;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:nth-child(2) {
        transform: rotate(180deg);
    }

    &:hover {
        opacity: 0.7;
    }
`;

const Slider = ({ image }) => {
    const [slide, setSlide] = useState(0);

    const moveLeft = () => {
        if (slide > 0) {
            setSlide(slide - 1);
            return;
        }
        setSlide(2);
    }
    const moveRight = () => {
        if (slide < 2) {
            setSlide(slide + 1);
            return;
        }
        setSlide(0);
    }

    return (
        <SliderStyles>
            <SliderImage fluid={image} />
            <SliderContainer>
                <SliderCarousel>
                    <SliderCarouselWrapper slideNumber={slide}>
                        <SliderItem>
                            <SliderBorder></SliderBorder>
                            <h1>Pośrednictwo w obrocie <b>nieruchomościami</b></h1>
                            <p>Biuro nieruchomości NOWU Karwowski w Kaliszu zajmuje się pomocą w sprzedaży, kupnie i wynajmie nieruchomości. Działamy kompleksowo, aby zapewnić obsługę na najwyższym poziomie. Zapraszamy do kontaktu z naszymi doradcami.</p>
                        </SliderItem>
                        <SliderItem>
                            <SliderBorder></SliderBorder>
                            <h1>Kompleksowa pomoc w <b>ubezpieczeniu</b></h1>
                            <p>W biurze NOWU Karwowski w Kaliszu ubezpieczymy Ciebie i twój majątek. Dzięki współpracy z wieloma towarzystwami dopasujemy do twoich potrzeb ubezpieczenie majątkowe, komunikacyjne i podróżne. Zapraszamy do kontaktu i sprawdzenia naszej oferty.</p>
                        </SliderItem>
                        <SliderItem>
                            <SliderBorder></SliderBorder>
                            <h1>Pomożemy w uzyskaniu <b>odszkodowania</b></h1>
                            <p>W biurze NOWU Karwowski w Kaliszu zapewniamy profesjonalną pomoc prawną osobom, które uległy wypadkowi. Pomożemy w uzyskaniu odszkodowania lub zadośćuczynienia za doznaną krzywdę.</p>
                        </SliderItem>
                    </SliderCarouselWrapper>
                </SliderCarousel>
                <SliderControls>
                    <SliderNav aria-label="Previous" tabindex="0" role="button" onClick={(e) => moveLeft(e)}><SVGChevron /></SliderNav>
                    <SliderNav aria-label="Next" tabindex="0" role="button" onClick={(e) => moveRight(e)}><SVGChevron /></SliderNav>
                </SliderControls>
            </SliderContainer>
        </SliderStyles >
    );
};

export default Slider;