import React from "react";
import styled from "styled-components";

const CardStyles = styled.a`
    display: flex;
    flex-direction: column;
    height: 180px;
    width: calc(33% - 24px - (140px / 3));
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 16px 64px rgba(0, 0, 0, 0.1);
    padding: 24px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    z-index: 90;
    margin-right: 24px;

    @media (max-width: 1280px) {
        width: calc(33% - (140px / 3));
    }

    @media (max-width: 992px) {
        width: 100%;
        margin: 0 0 24px 0;
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05);
    }

    &:hover {
        background-color: #f1f1f1;
        transform: translateY(-4px);
    }
`;

const CardIcon = styled.div``;

const CardSubtitle = styled.div`
    color: #6f6f6f;
    margin-top: auto;
`;

const CardTitle = styled.div`
    font-weight: 600;
    color: #000;
    font-size: 24px;
`;

const Card = ({ icon, title, link }) => {
    return (
        <CardStyles href={`${link}`}>
            <CardIcon>{icon}</CardIcon>
            <CardSubtitle>Przejdź</CardSubtitle>
            <CardTitle>{title}</CardTitle>
        </CardStyles>
    );
};

export default Card;