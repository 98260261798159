import React from "react";
import styled from "styled-components";

const LinksStyles = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 1280px;
    margin: -80px auto 0;
    box-sizing: border-box;

    @media (max-width: 1280px) {
        width: 100%;
        padding: 0 60px;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        margin: 40px 0;
        padding: 0 30px;
    }
`;

const Links = ({ children }) => {
    return (
        <LinksStyles>{children}</LinksStyles>
    );
};

export default Links;